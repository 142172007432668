import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { usePageView } from '../../hooks/useTracking';
import { actions } from '../../store/carRegistration';
import { StaticImage } from '../Image';
import T from '../Translate';
import { useWizard, type WizardChildProps } from '../Wizard';

import CarRegistrationLayout from './CarRegistrationLayout';

export default function CarRegistrationCebia(_: WizardChildProps) {
  const dispatch: DispatchFunction = useDispatch();
  const wizard = useWizard();

  usePageView('addcar_cebia');

  useEffect(() => {
    (async () => {
      await dispatch(actions.callCebia());
      wizard.goForward();
    })();
  }, []);

  return (
    <CarRegistrationLayout hideBackButton hideSupport>
      <div className="d-flex flex-column align-items-center py-5">
        <StaticImage alt="cebia" name="cebia-rgb" />
        <T as="p" className="text-center mt-2" id="car.registration.cebia.description" />
      </div>
    </CarRegistrationLayout>
  );
}
